import React,{useState} from 'react'
import Header from '../split/Header'
import Footer from '../split/Footer'

const Refundpolicy = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);

  return (
    <>
    <div
    className="mt-0"
    style={{
      background:
        "url(assets/images/background/banner_bg.jpg) no-repeat center center /cover"
    }}
  >
    <Header />
    <div className='text-center heading ' style={{paddingTop:isMobile?"25%":"12%"}}>
        <h2>Cancellation and Refund Policy</h2>
        <h6 style={{paddingLeft:'5%',paddingRight:'5%',marginTop:'5%'}}>IslamicTijarat™️ is dedicated to providing an educational and valuable service to its subscribers, and we are committed to ensuring transparency regarding subscription cancellations and refunds. This policy outlines the terms governing cancellations and refunds and serves as a legally binding agreement between IslamicTijarat™️ (under ITG EDUTECH LLP) and its users. Please read it carefully before making any subscription decisions.</h6>

    </div>
    <div className='contentall' >
    <div className=''>
    <h5>1.Scope of Policy:</h5>
    <p>This Cancellation and Refund Policy applies to all subscription services provided by IslamicTijarat™️. By subscribing, you acknowledge and agree to these terms. If you do not agree with these terms, please refrain from subscribing to our services.</p>
    </div>
    <div className='marginalignment'>
    <h5>2.Refund Eligibility and Request Timeframe:</h5>
    <p>Eligibility: Refunds are available only if requested within 24 hours of the initial subscription purchase. Refund requests made outside this 24-hour period are automatically ineligible, and the subscription fee becomes non-refundable.</p>
    <p>Automatic Non-Eligibility: Refunds cannot be requested for the following reasons, even within the 24-hour window:</p>
    <p>Change of mind after subscription</p>
    <p>Non-usage of the service or subscription features</p>
    <p>Incompatibility issues with other software or devices
    </p>
    <p>Misunderstanding or misinterpretation of the subscription features or services provided</p>
    </div>
    <div className='marginalignment'>
    <h5>3.Cancellation Process:</h5>
    <p>Auto-Renewal: All subscriptions are set to auto-renew at the end of each term unless manually canceled by the user.</p>
    <p>Cancellation Window: To avoid renewal charges, subscribers must cancel their subscription at least 24 hours before the end of the subscription term. Cancellations are effective from the next billing cycle and do not apply retroactively.</p>
   <h5>How to Cancel:</h5>
   <p>1. In-App Cancellation: Users may cancel subscriptions directly within their account settings on the IslamicTijarat™️ platform.</p>
    <p>2. Customer Support: Alternatively, users may contact IslamicTijarat™️ customer support to request cancellation assistance.</p>
    
    
    
    </div>
    <div className='marginalignment'>
    <h5>4.Refund Request Process:</h5>
    <p>If you wish to request a refund within the 24-hour eligibility timeframe:</p>
    <p>1. Submit a Formal Request: Contact IslamicTijarat™️ customer support within 24 hours of your purchase. Provide your subscription ID, registered email, and any additional details necessary for verification.</p>
   <p>2. Verification of Request: IslamicTijarat™️ reserves the right to review and verify refund requests to ensure compliance with this policy. Verification may include reviewing payment records and subscription details.</p>
   <p>3. Approval or Rejection: Once verified, IslamicTijarat™️ will notify you of the approval or rejection of your refund request.</p>
    </div>
    <div className='marginalignment'>
    <h5>5.Refund Processing and Payment:</h5>
    <p>Refund Method: Approved refunds will be issued to the original payment method only. No alternate refund options (e.g., cash or transfer to a different account) will be provided.
    </p>
    <p>Processing Time: Refunds may take 7–10 business days to process after approval, depending on your financial institution.</p>
    <p>Currency and Fees: Refunds are issued in the original purchase currency. IslamicTijarat™️ is not responsible for currency exchange rates or any additional fees imposed by the user’s bank or payment provider.
    </p>
    </div>
    <div className='marginalignment'>
    <h5>6.Non-Refundable Circumstances:</h5>
    <p>Beyond the 24-hour refund eligibility period, no refunds will be granted under any circumstances, including but not limited to:
    </p>
    <p>Dissatisfaction with service or features after use</p>
    <p>Failure to cancel a subscription before auto-renewal</p>
    <p>Technical issues or compatibility problems unrelated to IslamicTijarat™️</p>
    <p>Violation of the terms of use or any misconduct on the Platform
    </p>

    </div>
    <div className='marginalignment'>
    <h5>7.Auto-Renewal, Renewals, and Cancellation Terms:</h5>
    <p>Auto-Renewal Policy: All IslamicTijarat™️ subscriptions auto-renew at the end of each term unless canceled prior to renewal.</p>
    <p>No Refunds on Renewals: Refunds are not provided for renewals. Users are responsible for canceling subscriptions prior to the renewal date to avoid future charges.</p>
    <p>Termination of Services: IslamicTijarat™️ reserves the right to terminate user subscriptions for any violations of the terms or misuse of services, with no refunds granted for terminated accounts.</p>
    </div>
  
    <div className='marginalignment'>
    <h5>8.Legal Agreement and Dispute Resolution:</h5>
    <p>Acceptance of Policy: By subscribing, you acknowledge and agree to abide by this Cancellation and Refund Policy.</p>
    <p>Waiver of Dispute: You waive the right to dispute or contest charges beyond the eligibility timeframe defined in this policy.</p>
    <p>Governing Law and Jurisdiction: This policy is governed by the laws of [Jurisdiction]. Any disputes related to this policy will be resolved exclusively in the courts of [Jurisdiction].</p>
    </div>
    <div className='marginalignment'>
    <h5>9.Limitation of Liability:</h5>
    <p>IslamicTijarat™️, ITG EDUTECH LLP, and its affiliates, employees, or agents are not liable for any damages, losses, or claims arising from denied refund requests, policy enforcement, or user-initiated cancellation. Users agree that this policy is final and legally binding.
    </p>
    <p>For any questions or concerns regarding this policy, please contact IslamicTijarat™️ support. Note that this Cancellation and Refund Policy may be revised periodically at IslamicTijarat™️'s discretion, and any changes will be effective immediately upon posting.</p>
    

    </div>
    <div className='marginalignment'>
    
    <h6>By using IslamicTijarat™️ services, you confirm your understanding and acceptance of this Cancellation and Refund Policy in its entirety.</h6>

    </div>
    </div>
    <Footer />
    </div>
   
        </>
  )
}

export default Refundpolicy