import React from 'react'
import { useNavigate, useLocation } from "react-router-dom";
import { Link } from 'react-scroll';
const Footer = () => {
  const navigate = useNavigate();
  return (
         <footer className="xs-footer-sec">
      <div className="container">
        <div className="footer-area">
          <div className="row">
            <div className="col-lg-3 col-sm-6">
              <div className="footer-widget">
                <div className="footer-logo">
                <a className="nav-brand d-flex" href="#">
          <img src="/logo1.png" style={{marginRight:'5px',marginLeft:'-2px'}} alt="" width={45} />
          <img src="/logo2.png" alt="" width={250} />
        </a>
                </div>
                <p>
                Empowering Ethical Trade Worldwide
                </p>
                <h6>Address</h6>
                <p>Shop No.2, Pearl Oasis Apartment,Pathanwadi, Malad East,Mumbai - 400097</p>
              </div>
            </div>
            {/* col end */}
            <div className="col-lg-2 offset-lg-1 col-sm-6">
              <div className="footer-widget">
                <h4 className="widget-title">Company</h4>
                <ul>
                  
                  <li>
                  <Link to="about_us" smooth={true} duration={1500} className="scrolls" style={{ cursor: 'pointer' }}>
                  About us
                </Link>
               
                  </li>
                  <li>
                    <a href="#"> Our Services</a>
                  </li>
                
                  <li>
                    <a href="/privacy-policy">Privacy Policy</a>
                  </li>
                  <li>
                    <a href="/Cancellation_Refund_Policy">Refund Policy</a>
                  </li>
                 
                </ul>
              </div>
            </div>
            {/* col end */}
            <div className="col-lg-2 col-sm-6">
              <div className="footer-widget support">
                <h4 className="widget-title">Support</h4>
                <ul>
                  <li>
                    <a href="#"> Contact Us</a>
                  </li>
                  <li>
                    <a href="#"> FAQ</a>
                  </li>
                  <li>
                    <a  href="/Terms_Conditions">Terms and Conditions</a>
                  </li>
                </ul>
              </div>
            </div>
            {/* col end */}
            <div className="col-lg-3 offset-lg-1 col-sm-6">
              <div className="footer-widget">
                <h4 className="widget-title">Contact</h4>
                <form method="get" className="widget-subscibe">
                  <input
                    type="email"
                    name="email"
                    className="subscribe-email"
                    placeholder="Email"
                  />
                  <button type="submit" className="subs-btn">
                    <i className="fa fa-paper-plane" />{" "}
                  </button>
                </form>
                <ul>
                  <li className='text-white'>Tel: 009-215-5596 ( Toll free )</li>
                  <li className='text-white'>
                    Email:{" "}
                    <a
                      href="../cdn-cgi/l/email-protection.html"
                      className="__cf_email__"
                      data-cfemail="2b42454d446b4e534a465b474e05484446"
                    >
                      [email&nbsp;protected]
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            {/* col end */}
          </div>
          {/* row end */}
        </div>
        {/* footer area end */}
        {/* copyright area */}
        <div className="footer-copyright">
          <p>Copyright © IslamicTijarat™️ </p>
        </div>
      </div>
      {/* container end */}
    </footer>

  )
}

export default Footer