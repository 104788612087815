import React from 'react'

const Sidecard = () => {
  return (
    <div className="xs-sidebar-group cart-group">
  <div className="xs-overlay xs-bg-black" />
  <div className="xs-sidebar-widget">
    <div className="sidebar-widget-container">
      <div className="widget-heading media">
        <div className="media-body">
          <a href="index.html#" className="close-side-widget">
            <i className="icon icon-cross" />
          </a>
        </div>
      </div>
      <div className="xs-empty-content">
        <h3 className="widget-title">Shopping cart</h3>
        <h4 className="xs-title">No products in the cart.</h4>
        <p className="empty-cart-icon">
          <i className="icon icon-shopping-cart" />
        </p>
        <p className="xs-btn-wraper">
          <a className="btn btn-primary" href="index.html#">
            Return To Shop
          </a>
        </p>
      </div>
    </div>
  </div>
</div>

  )
}

export default Sidecard