import './App.css';
import {
  BrowserRouter as Router,Routes,
  Route,
  Navigate,
  BrowserRouter,
} from "react-router-dom";
import Home from './Component/Home';
import Privacy_policy from './Component/Privacy/Privacy_policy';
import Refundpolicy from './Component/Refundpolicy/Refundpolicy';
import Termsandcondotion from './Component/Termsandpolicy/Termsandcondotion';


function App() {
  const routes = [
    { path: "/", element:<Home /> },
    { path: "/privacy-policy", element:<Privacy_policy /> },
    { path: "/Cancellation_Refund_Policy", element:<Refundpolicy /> },
    { path: "/Terms_Conditions", element:<Termsandcondotion /> },
    
  ];
  return (
    <Router>
    <Routes>
      {routes.map((route, index) => (
        <Route key={index} path={route.path} element={route.element} />
      ))}
    </Routes>
  </Router>
  );
}

export default App;
