import React, { useEffect } from 'react';

const TradingViewWidget = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-timeline.js';
    script.async = true;
    script.innerHTML = JSON.stringify({
      feedMode: "all_symbols",
      isTransparent: true,
      displayMode: "regular",
      width: "100%",
      height: "100%",
      colorTheme: "dark",
      locale: "en"
    });
    
    document.getElementById('tradingview-widget').appendChild(script);

    return () => {
      // Cleanup: Remove the script when the component is unmounted
      document.getElementById('tradingview-widget').innerHTML = '';
    };
  }, []);

  return (
    <div className="tradingview-widget-container" >
      <div id="tradingview-widget" className="tradingview-widget-container__widget" style={{height:'550px'}}></div>
      <div className="tradingview-widget-copyright">
       
     
      </div>
    </div>
  );
};

export default TradingViewWidget;
