import React,{useState} from 'react'
import { TypeAnimation } from 'react-type-animation';

const Particles = () => {

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);
  return (
    <div id="particles-js">
      {/* banner start */}
      <section className="banner-sec" id="particles-js1">
        <div
          className="banner-item"
          style={{
            background:
              "url(assets/images/background/banner_bg.jpg) no-repeat center center /cover"
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12 mx-auto ">
                <div className="banner-content">
                  <h1 className="banner-title" >
                    Islamic Tijarat™
                    {/* <TypeAnimation
          sequence={[
            '',
            2000,
            '', 
            1000, 
            'Islamic Tijarat™',
            2000,
            '', 
            1000, 
        ]}
          wrapper="span"
      speed={-100}
      style={{ display: 'inline-block' }}
      repeat={Infinity}
          /> */}

                  </h1>
                  <p>
                    At Islamic Tijarat , we redefine what it means to invest with faith. Our industry-leading Shariah screening technology and uncompromising compliance standards make your investment journey seamless and precise, so you never have to choose between profits and principles.
                    Pure Profits. Absolute Peace of Mind.
                    Invest the Right Way Shariah Compliant, Always.              </p>
                  <a href=""  className="btn btn-primary">
                  Empowering Ethical Trade
                  </a>
                </div>
              </div>
            </div>
            <div className="banner-img-item">
              <img
                className="banner-img"
                src="assets/images/banner/banner_img.png"
                alt=""
              />
              <img
                className="banner-ico banner-ico-1"
                style={{ width: '60px' }}

                src="/itg.png"
                alt=""
              />
              <img
                className="banner-ico banner-ico-2"
                style={{ width: '60px' }}
                src="/itg.png"
                alt=""
              />
              <img
                className="banner-ico banner-ico-3"
                style={{ width: '75px' }}
                src="/itg.png"
                alt=""
              />
              <img
                className="banner-ico banner-ico-4"
                style={{ width: '60px' }}
                src="/itg.png"
                alt=""
              />
              <img
                className="banner-ico banner-ico-5"
                style={{ width: '70px' }}
                src="/itg.png"
                alt=""
              />
            </div>
          </div>
          {/*  .cont  ainer END */}
        </div>
      </section>{" "}
      {/* banner section end */}
      <div className="featured-area" id='about_us'>
        <div className="blockcain-and-featured-area">
          {/* blockcain section start */}
          <section className="blockcain-top-sec">
            <div className="container">
              <div
                style={{
                  marginTop: "3%",
                  marginBottom: "5%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center", // Aligns content vertically
                  textAlign: "center",  // Centers text within the div
                }}
              >
                <h3 style={{
                  lineHeight: "50px"
                }}>
                  Discover the clarity and confidence of Shariah-compliant investing. With Islamic Tijarat, navigate your financial choices with insights rooted in integrity and faith.
                </h3>
              </div>

              <div className="row">

                <div className="col-md-6 wow fadeInUp" data-wow-duration="1.5s">
                  <div className="blockcain-top">
                    <h2 className="column-title">
                      Start Your Ethical Investment Journey
                    </h2>
                    <img src="assets/images/islamic/journy.png" />
                  </div>
                </div>
                {/* col end */}
                <div
                  className="col-md-5 offset-md-1 wow fadeInUp"
                  data-wow-duration="2s"
                >
                  <div className="blockcain-top-content ">

                    <h4>
                      Welcome to Islamic Tijarat
                    </h4>
                    <h5>
                      Islamic Tijarat is dedicated to helping you make informed decisions with confidence, aligning your financial path with the values that matter most
                    </h5>
                    <p>Faith-Based Integrity – We meticulously screen each investment to ensure it aligns with Islamic principles, offering you transparency and peace of mind.
                      Informed Choices – Receive detailed insights and clarity about Shariah compliance, empowering you to navigate the market with confidence.
                      Trusted Expertise – With a team of seasoned scholars and financial experts, we provide dependable, principled guidance for every step of your financial journey.</p>

                  </div>
                </div>
              </div>
            </div>
          </section>{" "}
          {/* blockcain section end */}
          {/* featured section start */}
          <section className="featured-sec" id="features">
            <div className="container">
              <div className="row">
                <div className="col-lg-8 mx-auto">
                  <div className="section-title-item">
  
                    <h2 className="section-title">Islamic Tijarat Features</h2>
                  </div>
                </div>
              </div>
              {/* row end */}
              <div className="main-fetured-item">
                <div className="row">
                  <div className="col-md-3 wow fadeInUp" data-wow-duration="1.5s">
                    <div className="single-feaured-item">
                      <img src="assets/images/islamic/2.png" width={150} alt="" />
                      <h3 className="feature-title">Stock Screener</h3>
                      <p>
                        Discover stocks with precision using advanced filters to assess Shariah compliance and ethical alignment.

                      </p>
                    </div>
                  </div>
                  {/* col end */}

                  <div className="col-md-3 wow fadeInUp" data-wow-duration="2s">
                    <div className="single-feaured-item">
                      <img src="assets/images/islamic/3.png" width={150} alt="" />
                      <h3 className="feature-title">IPO Screener</h3>
                      <p>
                      An IPO screener in Islamic tijarat identifies companies offering Shariah-compliant IPOs by ensuring adherence to Islamic law.
                      </p>
                    </div>
                  </div>
                  <div className="col-md-3 wow fadeInUp" data-wow-duration="2s">
                    <div className="single-feaured-item">
                      <img src="assets/images/islamic/4.png" width={150} alt="" />
                      <h3 className="feature-title">Purification Calculator</h3>
                      <p>
                        Did you know that investing in halal stocks may still generate non-compliant income. Start calculating your purification amount now.

                      </p>
                    </div>
                  </div>
              
                  <div className="col-md-3 wow fadeInUp" data-wow-duration="2.5s">
                    <div className="single-feaured-item">
                      <img src="assets/images/islamic/1.png" width={150} alt="" />
                      <h3 className="feature-title">Zakat Calculator</h3>
                      <p>
                        Calculate your zakat obligations easily with our user-friendly calculator, ensuring compliance with Islamic principles.
                      </p>
                    </div>
                  </div>
     
                </div>
       
              </div>
      
              <div
                className="featured-poligonal-img wow fadeInUp"
                data-wow-duration="1.5s"
              >
                <img
                  className="poligonal-img"
                  src="assets/images/feature/poligonal.png"
                  alt=""
                />
              </div>
            </div>
          </section>{" "}
        </div>
        <div className="blockcain-and-logo-area">
          <section className="blockcain-business-sec" id="how-it-works">
            <div className="container">
              <div className="row">
                <div
                  className="col-md-6 col-lg-6 wow fadeInUp"
                  data-wow-duration="1.5s"
                >
                  <div className="blockcain-img">
                    <img src="assets/images/blockchain/block_img.png" alt="" />
                  </div>
                </div>
                <div
                  className="col-md-6 col-lg-5 offset-lg-1 wow fadeInUp"
                  data-wow-duration="2s"
                >
                  <div className="blockcain-content">
                    <small className="xs-section-title">Discover Our Mission</small>
                    <h3 className="column-title">How We Ensure Shariah Compliance</h3>
                    Precision and Faith in Every Screened Stock
                    <p>
                      Islamic Tijarat’s advanced screening process offers you an assurance of compliance, so every choice is aligned with Islamic values.                </p>
                    <p>
                      Our Shariah-compliant process is designed to filter out companies and assets that do not meet Islamic standards. Here’s how we ensure each option is aligned with your values:

                      Rigorous Screening – Every investment is evaluated based on key Shariah principles, from prohibiting riba (interest) to avoiding unethical sectors.
                      Continuous Monitoring – Once screened, investments are monitored consistently to maintain compliance.
                      Clear, Concise Reports – Access easy-to-understand reports detailing the compliance status of each stock, so you can make informed choices with confidence.
                      Visual:
                      An infographic illustrating the 3-step process.
                    </p>

                  </div>
                </div>
              </div>
            </div>
          </section>{" "}
          <section className="client-logo-sec">
            <div className="container">
              <div className="client-logo-item">
                <div className="row owl-carousel" id="client-slider">
                  <div className="col-sm wow fadeInUp" data-wow-duration="1.5s">
                    <div className="client-logo">
                      <img src="assets/images/client_logo/logo_style1.png" alt="" />
                    </div>
                  </div>
                  <div className="col-sm wow fadeInUp" data-wow-duration="2s">
                    <div className="client-logo">
                      <img src="assets/images/client_logo/logo_style2.png" alt="" />
                    </div>
                  </div>
                  <div className="col-sm wow fadeInUp" data-wow-duration="2.5s">
                    <div className="client-logo">
                      <img src="assets/images/client_logo/logo_style3.png" alt="" />
                    </div>
                  </div>
                  <div className="col-sm wow fadeInUp" data-wow-duration="3s">
                    <div className="client-logo">
                      <img src="assets/images/client_logo/logo_style4.png" alt="" />
                    </div>
                  </div>
                  <div className="col-sm wow fadeInUp" data-wow-duration="3.5s">
                    <div className="client-logo">
                      <img src="assets/images/client_logo/logo_style5.png" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>{" "}
        </div>
        <div className="blockcain-and-featured-area">
          <section className="blockcain-top-sec">
            <div className="container">
              <div className="row">
                <div className="col-md-6 wow fadeInUp" data-wow-duration="1.5s">
                  <div className="blockcain-top">
                    <h2 className="column-title">
                      Learn More About Our Process
                    </h2>
                    <img src="assets/images/islamic/about.png" alt="" />
                  </div>
                </div>
                <div
                  className="col-md-5 offset-md-1 wow fadeInUp"
                  data-wow-duration="2s"
                >
                  <div className="blockcain-top-content ">
                    <h2 className="column-title">
                      Empowering You to Make Ethical Investment Decisions
                    </h2>
                    <p>
                      Gain a deeper understanding of what makes an investment halal or haram. Islamic Tijarat provides a wealth of resources to guide you on your journey, from fundamental concepts to advanced strategies in Shariah-compliant investing.
                    </p>
                    <h2 className="column-title">
                      Featured Articles:
                    </h2>
                    <p>
                      “Understanding Halal and Haram in Investments” – A straightforward guide on the principles that shape Shariah compliance.
                      “The Role of Riba in Islamic Finance” – Insights into why interest-based activities are avoided.
                      “How Islamic Tijarat Ensures Compliance” – Discover the criteria used in our screening process
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>{" "}
        </div>
      </div>
    </div>

  )
}

export default Particles