import React,{useState} from 'react'
import "./style.css"
import Header from '../split/Header'
import Footer from '../split/Footer'

const Privacy_policy = () => {

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);

  return (
    <>
    <div
    className="mt-0"
    style={{
      background:
        "url(assets/images/background/banner_bg.jpg) no-repeat center center /cover"
    }}
  >
    <Header />
    <div className='text-center heading' style={{paddingTop:isMobile?"25%":"12%"}}>
        <h2>Privacy Policy</h2>
    </div>
    <div className='contentall' >
    <h4>Last Updated:Nov 2024</h4>
    <div className='marginalignment'>
    <h5>1.Introduction:</h5>
    <p>Welcome to ISLAMIC TIJARAT™️, a product of ITG Edutech LLP (“we,” “our,” or “us”). Protecting your personal information and privacy is our priority. This Privacy Policy describes how ISLAMIC TIJARAT™️ collects, uses, shares, and protects information through our mobile application ("App"). By using our App, you agree to the terms outlined in this Privacy Policy.</p>
    <p>This policy complies with international data protection standards, including but not limited to the General Data Protection Regulation (GDPR), California Consumer Privacy Act (CCPA), and the Personal Data Protection Act (PDPA), ensuring transparency and protection for your data.</p>
    </div>
    <div className='marginalignment'>
    <h5>2.Scope of the Policy:</h5>
    <p>This Privacy Policy applies to all users of the ISLAMIC TIJARAT™️ App, including visitors, registered members, and anyone interacting with the application. It covers:</p>
    <p>Personal data that we collect.</p>
    <p>The legal basis for collecting and processing your data.</p>
    <p>How we use and disclose your data.</p>
    <p>Your rights under various privacy laws.</p>
    <p>Our data protection practices.</p>
    </div>
    <div className='marginalignment'>
    <h5>3.Information We Collect:</h5>
    <p>We collect different types of information to deliver a personalized experience and ensure compliance with applicable laws:</p>
    <p>Personal Information: This includes data such as your name, email address, phone number, and other contact information when you create an account or use our services. We may also collect information related to your transactions, payment details, and preferences.</p>
    <p>Technical and Usage Data: We may collect technical data about your device, internet connection, IP address, browser type, operating system, time zone settings, and usage patterns to analyze how users interact with the App and to improve performance.</p>
    <p>Location Data: With your consent, we may collect your location information to deliver location-based services and enhance the user experience. You may disable location tracking in your device settings.</p>
    <p>Shariah Compliance Preferences: As part of our commitment to ethical investing, we may collect your preferences related to Shariah-compliant products and services.</p>
    </div>
    <div className='marginalignment'>
    <h5>4.Shariah-Compliant Screening:</h5>
    <p>The Shariah-compliant screening in ISLAMIC TIJARAT™️ is provided solely by TASIS (Taqwaa Advisory and Shariah Investment Solutions). ISLAMIC TIJARAT™️ does not directly or indirectly participate in the screening process. All matters related to Shariah compliance are managed and controlled exclusively by TASIS, and any Shariah-related issues, concerns, or updates will be addressed by TASIS in accordance with their established criteria and guidelines.</p>
   
    </div>
    <div className='marginalignment'>
    <h5>5.Legal Basis for Processing Data:</h5>
    <p>We process your data based on one or more of the following legal bases:
    </p>
    <p>Consent: Where required, we seek your explicit consent to process your personal data for specific purposes.</p>
    <p>Contractual Obligations: Processing may be necessary for the performance of a contract with you or to provide services you request.</p>
    <p>Legal Compliance: We may process your personal data to comply with legal obligations or regulatory requirements.</p>
    <p>Legitimate Interest: Processing may be necessary for our legitimate business interests, such as improving the App, maintaining security, or preventing fraud.</p>
    </div>
    <div className='marginalignment'>
    <h5>6.How We Use Your Information:</h5>
    <p>We use your information for the following purposes:</p>
    <p>To provide, maintain, and improve our services.</p>
    <p>To personalize your experience and present content tailored to your interests.</p>
    <p>To manage your account and subscriptions.</p>
    <p>To communicate with you, including updates, promotions, and relevant notices.</p>
    <p>To comply with legal requirements and respond to lawful requests by authorities.</p>
    <p>To monitor, detect, and prevent fraudulent or suspicious activity.</p>
    </div>
    <div className='marginalignment'>
    <h5>7.Sharing and Disclosure of Your Information:</h5>
    <p>We do not share your personal data with third parties, except in the following circumstances:</p>
    <p>Service Providers: We may share information with trusted third-party service providers to assist us in operating our App, such as payment processors, analytics providers, and data storage services. These providers are obligated to maintain confidentiality and use your information only to perform services on our behalf.</p>
    <p>Legal and Regulatory Requirements: We may disclose your information if required by law, to comply with legal processes, or in response to government or regulatory requests.</p>
    <p>Protection of Rights: We may disclose your information to protect the rights, property, or safety of ISLAMIC TIJARAT™️, ITG Edutech LLP, its users, or others, in accordance with applicable laws.
    </p>
    </div>
  
    <div className='marginalignment'>
    <h5>8.International Data Transfers:</h5>
    <p>Your information may be transferred to and processed in countries outside your country of residence. Where this is the case, we ensure that adequate data protection measures are in place, such as the EU Standard Contractual Clauses, to protect your data.</p>
    </div>
    <div className='marginalignment'>
    <h5>9.Data Security and Retention:</h5>
    <p>We use industry-standard security measures to protect your personal data, including encryption, secure access controls, and regular security audits. Despite our efforts, no security measure is perfect, and we cannot guarantee the absolute security of your information.</p>
    <p>We retain your personal data only for as long as necessary to fulfill the purposes outlined in this Privacy Policy or as required by law.</p>
    

    </div>
    <div className='marginalignment'>
    <h5>10.Your Rights:</h5>
    <p>Depending on your location and applicable laws, you have the following rights concerning your personal data:</p>
    <p>Right to Access: You can request access to the personal data we hold about you.</p>
    <p>Right to Rectification: You have the right to request correction of inaccurate or incomplete information.</p>
    <p>
    Right to Erasure: You can request deletion of your personal data under certain circumstances.
    </p>
    <p>
    Right to Restrict Processing: You may request to restrict the processing of your data in specific situations.

    </p>
    <p>
    Right to Data Portability: You can request a copy of your data in a structured, machine-readable format.
    </p>
    <p>
    Right to Object: You have the right to object to the processing of your personal data based on legitimate interests or for direct marketing purposes.
    </p>
    <p>
    To exercise any of these rights, please contact us at  <a style={{color:"#fff"}} href="mailto:info@islamictijarat.com">info@islamictijarat.com</a>.
    </p>
    </div>
    <div className='marginalignment'>
    <h5>11.Children’s Privacy:</h5>
    <p>ISLAMIC TIJARAT™️ is not directed at children under the age of 13. We do not knowingly collect personal data from children. If we become aware that we have collected such information, we will delete it promptly.</p>
    </div>
    <div className='marginalignment'>
    <h5>12.Changes to This Privacy Policy:</h5>
    <p>ITG Edutech LLP reserves the right to update this Privacy Policy at any time. We will notify you of any significant changes by posting the new Privacy Policy on this page and updating the "Last Updated" date. Please review this policy periodically.</p>
    </div>
    <div className='marginalignment'>
    <h5>13.Contact Us:</h5>
    <p>If you have any questions, concerns, or requests regarding this Privacy Policy or the handling of your data, please reach out to us:</p>
    <h6>Email :<a style={{color:"#fff"}} href="mailto:info@islamictijarat.com"> info@islamictijarat.com</a>    </h6>
    <h6>Address: Shop No.2, Pearl Oasis Apartment,
    Pathanwadi, Malad East,
   Mumbai - 400097
    </h6>
    <h6>Phone: [Insert Phone Number]</h6>
    <p>This Privacy Policy is designed to protect the rights and privacy of our users while ensuring ISLAMIC TIJARAT™️, a product of ITG Edutech LLP, remains compliant with global standards and regulations.</p>

    </div>
    </div>
    <Footer />
    </div>
   
        </>
  )
}

export default Privacy_policy