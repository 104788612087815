import React from 'react'

const Mobileapp = () => {
  return (
<div className="document-and-team-area">
  {/* docuemnt section start */}
  <section className="doceumnt-sec section-padding">
    <div className="container">
      {/* row end */}
      <div className="row">
        <div className="col-lg-12 mx-auto wow fadeInUp" data-wow-duration="1.5s">
          {/* tab menu end */}
          <div className="road-map-tab">
            <div className="tab-content" id="v-pills-tabContent">
              <div
                className="tab-pane tab_item_1 show active"
                id="v-pills-home"
                role="tabpanel"
                aria-labelledby="v-pills-home-tab"
              >
                <div className="row">
                  <div className="col-md-6 align-self-center">
                    <div className="xs-tabs-content">
                      <h2 className="">
                        Download Islamic Tijarat™ App
                      </h2>
                      <p>
                      Experience the world of Islamic finance at your fingertips with the Islamic Tijarat™ App. Download now to access real-time market updates, trading insights, and Sharia-compliant investment opportunities.
                      </p>
                      <span className="release_vertion">
                        Latest Release 1.49.0
                      </span>
                      <ul className="download-logo ">
                        {/* <li>
                          <img
                            src="assets/images/documentation/windows_logo-1.png"
                            alt=""
                          />
                        </li> */}
                        {/* <li>
                          <img
                            src="assets/images/documentation/windows_logo-2.png"
                            alt=""
                          />
                        </li> */}
                        <li>
                          <img
                            src="assets/images/documentation/mac_ico.png"
                            alt=""
                          />
                        </li>
                        {/* <li>
                          <img
                            src="assets/images/documentation/linux_1.png"
                            alt=""
                          />
                        </li> */}
                        <li>
                          <img
                            src="assets/images/documentation/android_1.png"
                            alt=""
                          />
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-6 text-center">
                    <div className="xs-tabs-img">
                      <img
                        src="/phone1.png"
                        alt=""
                        width={300}
                      />
                    </div>
                  </div>
                </div>
              </div> 
            </div>
          </div>
        </div>
      </div>
      {/* row end */}
    </div>
    {/* container end */}
    <div className="documentaion-shap-img">
      <img
        className="d-shap-img-1 wow fadeInLeft"
        data-wow-duration="1.5s"
        id="leftglobe"
        src="assets/images/documentation/globe1.png"
        alt=""
      />
      <img
        className="d-shap-img-2 wow fadeInRight"
        data-wow-duration="1.5s"
        src="assets/images/documentation/globe2.png"
        alt=""
      />
    </div>
  </section>{" "}
</div>
  )
}

export default Mobileapp