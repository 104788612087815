import React, { useState } from 'react';
import { FiChevronDown } from 'react-icons/fi'; // Chevron down icon from react-icons
import './style.css'; // Custom CSS for updated design

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    // Toggle between opening and closing the accordion items
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="document-and-team-area" id="faq" >
      <section className="doceumnt-sec section-padding">
        <div className="container">
          <section className="faq-section" >
            <div className="faq-container">
              <div className="faq-header">
                <h4>FAQ</h4>
                <h2>Frequently Asked Questions</h2>
              </div>
              <ul className="accordion">
                {faqItems.map((item, index) => (
                  <li
                    key={index}
                    className={`accordion-item ${activeIndex === index ? 'active' : ''}`}
                  >
                    <div
                      className="accordion-title"
                      style={{ borderRadius: '20px', marginTop: '5%', cursor: 'pointer' }}
                      onClick={() => toggleAccordion(index)} // Move the onClick handler here
                    >
                      <h3 className="text-dark">{item.question}</h3>
                      <FiChevronDown className={`icon ${activeIndex === index ? 'rotate' : ''}`} />
                    </div>
                    {activeIndex === index && ( // Conditionally render the content based on activeIndex
                      <div className="accordion-content">
                        <p>{item.answer}</p>
                      </div>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          </section>
        </div>

        <div className="documentaion-shap-img">
          <img
            className="d-shap-img-1 wow fadeInLeft"
            data-wow-duration="1.5s"
            id="leftglobe"
            src="assets/images/documentation/globe1.png"
            alt=""
          />
          <img
            className="d-shap-img-2 wow fadeInRight"
            data-wow-duration="1.5s"
            src="assets/images/documentation/globe2.png"
            alt=""
          />
        </div>
      </section>
    </div>
  );
};

const faqItems = [
  {
    question: 'What makes a stock halal or haram in Islamic finance?',
    answer:
      'In Islamic finance, a stock is considered halal if it meets certain criteria that align with Sharia (Islamic law). These include: Nature of Business: The company must not engage in prohibited (haram) activities, such as gambling, alcohol production, pork-related products, tobacco, and interest-based financial services.Financial Ratios: The company’s financials should meet certain criteria, like minimal debt and limited earnings from interest, as high reliance on debt or interest income is seen as un-Islamic.Ethical Operations: The company’s business practices should uphold ethical standards, avoiding deceit and harm.These factors help Muslims invest in ways that reflect their faith values.',
  },
  {
    question: 'How does Islamic Tijarat ensure continuous compliance monitoring?',
    answer:
      'Islamic Tijarat likely maintains continuous compliance monitoring by regularly reviewing the financials and business activities of companies against established Sharia-compliant criteria. They may use a combination of automated screening tools and human oversight to track changes in financial ratios, business practices, and income sources, ensuring alignment with Islamic principles. They may also collaborate with Sharia scholars to validate compliance updates. This helps in keeping the approved investments within the permissible boundaries over time.',
  },
  {
    question: 'What types of industries are typically excluded in Shariah-compliant investing?',
    answer:
      'In Shariah-compliant investing, certain industries are typically excluded because they involve activities considered haram (forbidden) in Islam. These include:Alcohol and Tobacco: Producing or selling alcohol and tobacco products.Gambling and Gaming: Casinos, betting companies, and other gambling-related businesses.Pork and Non-Halal Food Production: Companies involved in pork products or food not compliant with halal standards.Conventional Financial Services: Banks and insurance firms reliant on interest-based income.Entertainment Sectors: Industries promoting adult content or highly speculative media.These exclusions aim to uphold ethical and religious principles in investing.',
  },
  {
    question: 'How is income from halal investments calculated?',
    answer:
    'Income must be derived primarily from permissible sources, excluding revenue from interest or prohibited activities.'
  },
  {
    question: 'What financial ratios are used in Shariah screening?',
    answer:
    'Key ratios include debt-to-equity and interest income limits, with thresholds often below 30% to meet compliance.'
  },
  {
    question: 'How are mixed-source companies treated?',
    answer:
   'If a company has minor haram income, it might still qualify if purification (donating the prohibited portion) is practiced.'
  },
  // {
  //   question: 'What happens if a company loses compliance?',
  //   answer:
  //   'Investors typically have a grace period to sell shares when a stock falls out of Shariah compliance.'
  // },
  // {
  //   question: 'Can Shariah-compliant investments include real estate?',
  //   answer:
  //   'Yes, real estate investments are allowed as long as they exclude properties used for prohibited activities, like casinos.'
  // },
  // {
  //   question: 'How are Islamic financial products certified?',
  //   answer:
  //   'Shariah boards of scholars review and certify products for compliance, ensuring they meet Islamic financial principles.'
  // },
  // {
  //   question: 'How do investors purify their income?',
  //   answer:
  //   'Investors can donate the portion of income derived from haram activities to charity to purify their earnings.'
  // },
  // {
  //   question: 'Can Islamic finance products be used for personal loans?',
  //   answer:
  //   'Yes, but they must avoid interest-based transactions; alternatives like profit-sharing or leasing are used instead.'
  // },
  // {
  //   question: 'How does one verify a company s Shariah compliance?',
  //   answer:
  //   'Investors can check if a company is listed in Shariah-compliant indices or consult with financial institutions offering Shariah-compliant investment products.'
  // },
];

export default FAQ;
