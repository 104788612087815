import React from 'react'
import Roadmap from './Roadmap'

const Howworks = () => {
  return (
    <div className="how-work-and-token-area"  >
    <section className="how-work-sec section-padding" id="how_work">
    <div className="blockcain-and-featured-area client-logo-item">
    <Roadmap />
      {/* blockcain section start */}
      <section className="blockcain-top-sec ">
        <div className="container">
          <div className="row">
            <div className="col-md-6 wow fadeInUp" data-wow-duration="1.5s">
              <div className="blockcain-top">
                <h2 className="column-title">
                Visit Our Knowledge Center
                                           </h2>
                                           <img src="assets/images/islamic/visit.png" alt="" />
              </div>
            </div>
            {/* col end */}
            <div
              className="col-md-5 offset-md-1 wow fadeInUp"
              data-wow-duration="2s"
            >
              <div className="blockcain-top-content ">
              <h2 className="column-title">
              Real-Time Compliance Monitoring
                                           </h2>
                <p>
                With Islamic Tijarat’s real-time alerts, keep track of any compliance updates in your investments to maintain peace of mind.                </p>
                <p>
                Our monitoring system keeps you informed if a previously compliant investment status changes, ensuring you’re always aligned with Islamic guidelines. Alerts are tailored with clear explanations, so you can understand the reasons behind each status change.
                </p>
               
              </div>
            </div>
          </div>
        </div>
      </section>{" "}
      {/* blockcain section end */}
      {/* featured section start */}
  
      {/* featured section end */}
    </div>
  
    </section>{" "}
    {/* how work section end */}

  </div>
  
  )
}

export default Howworks