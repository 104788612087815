import React from 'react'

const Roadmap = () => {
  return (
    <div className="token-roadmap-area">
    {/* how work section start */}
    {/* <section className="token-distribution-sec section-padding" style={{}}>
  <div className="container">
    <div className="row">
      <div className="col-lg-8 mx-auto">
        <div className="section-title-item">
          <small className="xs-section-title">Token Details</small>
          <h2 className="section-title">Token Distribution</h2>
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col-lg-6 wow fadeInUp" data-wow-duration="1.5s">
        <div className="row chart-gap">
          <div className="col-sm-7 ml-lg-auto">
            <canvas className="token-chart-item" id="myChart" />
            <h3 className="xs-single-title">Token Distribution</h3>
          </div>{" "}
          <div className=" col-sm-5">
            <ul className="chart-label">
              <li>
                <img src="/images/token/label_img1.png" alt="" />
                <span className="chart-bg1">10 %</span> To Community
              </li>
              <li>
                <img src="/images/token/label_img2.png" alt="" />
                <span className="chart-bg2">08 %</span> Reserved Fund
              </li>
              <li>
                <img src="/images/token/label_img3.png" alt="" />
                <span className="chart-bg3">12 %</span> Advisor Team
              </li>
              <li>
                <img src="/images/token/label_img4.png" alt="" />
                <span className="chart-bg4">70 %</span> Sold Globaly
              </li>
            </ul>
          </div>{" "}
        </div>{" "}
      </div>{" "}
      <div className="col-lg-6 wow fadeInUp" data-wow-duration="2s">
        <div className="row chart-gap">
          <div className="col-sm-7 ml-lg-auto">
            <canvas className="token-chart-item" id="myChartTwo" />
            <h3 className="xs-single-title">Sale Contribution</h3>
          </div>{" "}
          <div className="col-sm-5">
            <ul className="chart-label">
              <li>
                <img src="assets/images/token/label_img1.png" alt="" />
                <span className="chart-bg1">10 %</span> To Community
              </li>
              <li>
                <img src="assets/images/token/label_img2.png" alt="" />
                <span className="chart-bg2">08 %</span> Reserved Fund
              </li>
              <li>
                <img src="assets/images/token/label_img3.png" alt="" />
                <span className="chart-bg3">12 %</span> Advisor Team
              </li>
              <li>
                <img src="assets/images/token/label_img4.png" alt="" />
                <span className="chart-bg4">70 %</span> Sold Globaly
              </li>
            </ul>
          </div>{" "}
    
        </div>{" "}

      </div>{" "}

    </div>{" "}
  </div>
</section> */}

    {/* how work section end */}
    {/* roadmap section start */}
    <section className="roadmap-sec section-padding" style={{}}>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-8 mx-auto">
            <div className="section-title-item">
              <small className="xs-section-title">Process</small>
              <h2 className="section-title">Company Roadmap</h2>
            </div>
          </div>
        </div>
        {/* row end */}
        <div className="roadmap-timeline">
          <img src="assets/images/roadmap/timelinered.png" alt="" />
          <div className="custom-container container">
            <div className="row roadmap-timeline-item">
              <div
                className="col-md xs-roadmap wow fadeInUp"
                data-wow-duration="1.5s"
              >
                <div className="single-roadmap-timeline">
                  <b />
                  <h3>April 2015</h3>
                  <p>Creation of Our Company</p>
                </div>
              </div>
              <div
                className="col-md xs-roadmap wow fadeInUp"
                data-wow-duration="2s"
              >
                <div className="single-roadmap-timeline">
                  <b />
                  <h3>April 2016</h3>
                  <p>Reach $20 Million Milestone</p>
                </div>
              </div>
              <div
                className="col-md xs-roadmap wow fadeInUp"
                data-wow-duration="2.5s"
              >
                <div className="single-roadmap-timeline">
                  <b />
                  <h3>March 2017</h3>
                  <p>Best ICO Brand</p>
                </div>
              </div>
              <div
                className="col-md xs-roadmap wow fadeInUp"
                data-wow-duration="3s"
              >
                <div className="single-roadmap-timeline">
                  <b />
                  <h3>May 2017</h3>
                  <p>Partnership with Urban Wolfs</p>
                </div>
              </div>
              <div
                className="col-md xs-roadmap wow fadeInUp"
                data-wow-duration="3.5s"
              >
                <div className="single-roadmap-timeline">
                  <b />
                  <h3>June 2018</h3>
                  <p>Invest in Bitcoin &amp; Lisk</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* container fluid end */}
    </section>{" "}
    {/* roadmap section end */}
  </div>
  
  )
}

export default Roadmap