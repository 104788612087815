import React from 'react'
import Mobileapp from './Mobileapp'

const Documentandteam = () => {
  return (
<div className="document-and-team-area">
  {/* docuemnt section start */}
  <section className="doceumnt-sec section-padding">
  
    {/* container end */}
    <div className="documentaion-shap-img">
      <img
        className="d-shap-img-1 wow fadeInLeft"
        data-wow-duration="1.5s"
        id="leftglobe"
        src="assets/images/documentation/globe1.png"
        alt=""
      />
      <img
        className="d-shap-img-2 wow fadeInRight"
        data-wow-duration="1.5s"
        src="assets/images/documentation/globe2.png"
        alt=""
      />
    </div>
  </section>{" "}

  {/* <section className="team-sec section-padding" id="team">
    <div className="container">
      <div className="row">
        <div className="col-lg-8 mx-auto">
          <div className="section-title-item">
            <small className="xs-section-title">Our experts</small>
            <h2 className="section-title">Team Members</h2>
          </div>
        </div>
      </div>
      <div className="row">
        <div
          className="col-lg-3 col-sm-6 wow fadeInUp"
          data-wow-duration="1.5s"
        >
          <div className="single-team">
            <div className="team-img">
              <img src="assets/images/team/team_1.jpg" alt="" />
              <div className="team-social">
                <a href="index.html#">
                  <i className="icon icon-facebook" />
                </a>
                <a href="index.html#">
                  <i className="icon icon-twitter" />
                </a>
                <a href="index.html#">
                  <i className="icon icon-linkedin" />
                </a>
              </div>
            </div>
            <div className="team-content">
              <h3 className="xs-title md">James Carter</h3>
              <p>CEO</p>
            </div>
          </div>
        </div>{" "}
        <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-duration="2s">
          <div className="single-team">
            <div className="team-img">
              <img src="assets/images/team/team_2.jpg" alt="" />
              <div className="team-social">
                <a href="index.html#">
                  <i className="icon icon-facebook" />
                </a>
                <a href="index.html#">
                  <i className="icon icon-twitter" />
                </a>
                <a href="index.html#">
                  <i className="icon icon-linkedin" />
                </a>
              </div>
            </div>
            <div className="team-content">
              <h3 className="xs-title md">Jenifar Lorany</h3>
              <p>Marketing Head</p>
            </div>
          </div>
        </div>{" "}
        <div
          className="col-lg-3 col-sm-6 wow fadeInUp"
          data-wow-duration="2.5s"
        >
          <div className="single-team">
            <div className="team-img">
              <img src="assets/images/team/team_3.jpg" alt="" />
              <div className="team-social">
                <a href="index.html#">
                  <i className="icon icon-facebook" />
                </a>
                <a href="index.html#">
                  <i className="icon icon-twitter" />
                </a>
                <a href="index.html#">
                  <i className="icon icon-linkedin" />
                </a>
              </div>
            </div>
            <div className="team-content">
              <h3 className="xs-title md">Michel Ramos</h3>
              <p>HR Head</p>
            </div>
          </div>
        </div>{" "}
        <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-duration="3s">
          <div className="single-team">
            <div className="team-img">
              <img src="assets/images/team/team_4.jpg" alt="" />
              <div className="team-social">
                <a href="index.html#">
                  <i className="icon icon-facebook" />
                </a>
                <a href="index.html#">
                  <i className="icon icon-twitter" />
                </a>
                <a href="index.html#">
                  <i className="icon icon-linkedin" />
                </a>
              </div>
            </div>
            <div className="team-content">
              <h3 className="xs-title md">Lorence Katie</h3>
              <p>Lead Designer</p>
            </div>
          </div>
        </div>{" "}

      </div>{" "}

    </div>
  </section>{" "} */}
</div>

  )
}

export default Documentandteam