import React,{useState} from 'react'
import { useNavigate, useLocation } from "react-router-dom";
import { Link } from 'react-scroll';
const Header = () => {
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);

  return (
    <header className="header">
  <div className="container" >
    <nav id="navigation1" className="navigation">
      <div className="nav-header">
        <a className="nav-brand" onClick={()=>{navigate('/')}}  style={{cursor:'pointer'}}>
          <img src="/logo1.png" style={{marginRight:'5px'}} alt="" width={35} />
          <img src="/logo2.png" alt="" width={200} />
        </a>
        <div className="nav-toggle" />
      </div>
      <div className="nav-menus-wrapper xs-menu">
        <ul className="header-right align-to-right">
          <li>
            <a href="https://islamictijarat.com" className="searchIcon">
              <i className="icon icon-user" />
            </a>
          </li>
        </ul>
        <ul className="nav-menu align-to-right" style={{display:isMobile?"none":''}}>
          <li className="active" style={{cursor:'pointer'}}>
            <a onClick={()=>{navigate('/')}} >Home</a>
          </li>
          <li>
          <Link to="features" smooth={true} duration={1500} className="scrolls" style={{ cursor: 'pointer' }}>
          Features
                </Link>
          </li>
          <li>
          <Link to="how-it-works" smooth={true} duration={1500} className="scrolls" style={{ cursor: 'pointer' }}>
                  How it Work
                </Link>
          </li>

          <li>
          <Link to="faq" smooth={true} duration={1500} className="scrolls" style={{ cursor: 'pointer' }}>
          FAQ
                </Link>
          </li>
        </ul>
      </div>
    </nav>
  </div>
  {/* .container END */}
</header>

  )
}

export default Header