import React from 'react'

const SidecardInfo = () => {
  return (
    <div className="xs-sidebar-group info-group">
    <div className="xs-overlay xs-bg-black" />
    <div className="xs-sidebar-widget">
      <div className="sidebar-widget-container">
        <div className="widget-heading">
          <a href="index.html#" className="close-side-widget">
            <i className="icon icon-cross" />
          </a>
        </div>
        <div className="sidebar-textwidget">
          <div className="sidebar-logo-wraper">
            <img src="assets/images/sidebar_logo_1.png" alt="sidebar logo" />
          </div>
          <p>
            Far far away, behind the word moun tains, far from the countries
            Vokalia and Consonantia, there live the blind texts. Separated they
            live in Bookmarksgrove right at the coast of
          </p>
          <ul className="sideabr-list-widget">
            <li>
              <div className="media">
                <div className="d-flex">
                  <i className="icon icon-placeholder2" />
                </div>
                <div className="media-body">
                  <p>759 Pinewood Avenue</p>
                  <span>Marquette, Michigan</span>
                </div>
              </div>
              {/* address 1 */}
            </li>
            <li>
              <div className="media">
                <div className="d-flex">
                  <i className="icon icon-email2" />
                </div>
                <div className="media-body">
                  <a href="../cdn-cgi/l/email-protection.html#335a5d555c73575c5e525a5d1d505c5e">
                    <span
                      className="__cf_email__"
                      data-cfemail="b3daddd5dcf3d7dcded2dadd9dd0dcde"
                    >
                      [email&nbsp;protected]
                    </span>
                  </a>
                  <span>Online Support</span>
                </div>
              </div>
              {/* address 1 */}
            </li>
            <li>
              <div className="media">
                <div className="d-flex">
                  <i className="icon icon-phone-call2" />
                </div>
                <div className="media-body">
                  <p>906-624-2565</p>
                  <span>Mon-Fri 8am-5pm</span>
                </div>
              </div>
              {/* address 1 */}
            </li>
          </ul>
          {/* .sideabr-list-widget */}
          <div className="subscribe-from">
            <p>Get Subscribed!</p>
            <form
              action="index.html#"
              method="POST"
              id="subscribe-form"
              className="subscribe-form"
            >
              <label htmlFor="sub-input" />
              <div className="form-group">
                <input
                  type="email"
                  name="email"
                  id="sub-input"
                  placeholder="Enter your mail here"
                  className="form-control"
                />
                <button className="sub-btn" type="submit">
                  <i className="icon icon-arrow-right" />
                </button>
              </div>
            </form>
          </div>
          <ul className="social-list version-2">
            <li>
              <a href="index.html#" className="facebook">
                <i className="fa fa-facebook" />
              </a>
            </li>
            <li>
              <a href="index.html#" className="twitter">
                <i className="fa fa-twitter" />
              </a>
            </li>
            <li>
              <a href="index.html#" className="linkedin">
                <i className="fa fa-linkedin" />
              </a>
            </li>
            <li>
              <a href="index.html#" className="instagram">
                <i className="fa fa-instagram" />
              </a>
            </li>
            <li>
              <a href="index.html#" className="vimeo">
                <i className="fa fa-vimeo" />
              </a>
            </li>
          </ul>
          {/* .social-list */}
          <div className="text-center">
            <a href="index.html#" className="btn btn-primary">
              Purchase Now
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  )
}

export default SidecardInfo