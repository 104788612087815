import React from 'react'
import Sidecard from "./split/Sidecard"
import SidecardInfo from "./split/SidecardInfo"
import Header from './split/Header'
import Blockandfooter from "./split/Blogandfooter"
import Documentandteam from "./split/Documentandteam"
import Howworks from "./split/Howworks"
import Particles from "./split/Particles"
import FAQ from './split/FAQ'
import Mobileapp from './split/Mobileapp'

const Home = () => {
    return (
        <>
            <Sidecard />
            <SidecardInfo />
            <Header />
            <Particles />
            <Howworks />
            <FAQ />
 
            <Documentandteam />
  
            <Blockandfooter />
            <div className="BackTo">
                <a href="index.html#" className="fa fa-angle-up" aria-hidden="true" />
            </div>


        </>
    )
}

export default Home