import React from 'react'
import Footer from './Footer'
import TradingViewWidget from './Trading'
import Mobileapp from './Mobileapp'

const Blogandfooter = () => {
  return (
    <div className="blog-and-footer-area">
    <div className="blog-and-social-area">
      {/* blog section start */}
      <section className="blog-sec section-padding" id="blog">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 mx-auto">
              <div className="section-title-item">
                <h2 className="section-title">Stocks News</h2>
              </div>
            </div>
          </div>
          {/* row end */}
          <div className="row p-1">
            <div style={{width:'100%',height:'600px',border:'2px solid #fff',padding:'5px',borderRadius:'10px'}}>
          <TradingViewWidget />
          </div>
      
            {/* Col End */}
         
            {/* Col End */}
          </div>{" "}
          {/* Row End */}
        </div>
      </section>{" "}
  <Mobileapp />
      {/* blog section end */}
      {/* social list section start */}
      <section className="social-list-sec section-padding" style={{}}>
        <div className="container">
        <h2 className="section-title mb-2">Follow Up</h2>
          <div className="row">
            <div
              className="col-lg col-6 col-md-3 wow fadeInUp"
              data-wow-duration=".5s"
            >
              <div className="single-social-list facebook">
                <i className="fa fa-facebook" />
                <h3 className="xs-title sm">Facebook</h3>
                <a href="https://www.facebook.com/islamictijarat" target='blank'> 
                  <span className="icon icon-arrow-right" />
                </a>
              </div>
            </div>
            {/* single-social-list*/}
            <div
              className="col-lg col-6 col-md-3 wow fadeInUp"
              data-wow-duration="1s"
            >
              <div className="single-social-list twitter">
                <i className="fa fa-twitter" />
                <h3 className="xs-title sm">Twitter</h3>
                <a href="https://x.com/islamictijarat" target='blank'>
                  <span className="icon icon-arrow-right" />
                </a>
              </div>
            </div>
            {/* single-social-list*/}
            <div
              className="col-lg col-6 col-md-3 wow fadeInUp"
              data-wow-duration="1.5s"
            >
              <div className="single-social-list github">
                <i className="fa fa-github-alt" />
                <h3 className="xs-title sm">Github</h3>
                <a href="#" target='blank'>
                  <span className="icon icon-arrow-right" />
                </a>
              </div>
            </div>
            {/* single-social-list*/}
            <div
              className="col-lg col-6 col-md-3 wow fadeInUp"
              data-wow-duration="2s"
            >
              <div className="single-social-list slack">
                <i className="fa fa-instagram" />
                <h3 className="xs-title sm">Instagram</h3>
                <a href="https://www.instagram.com/islamictijarat" target='blank'>
                  <span className="icon icon-arrow-right" />
                </a>
              </div>
            </div>
            {/* single-social-list*/}
            <div
              className="col-lg col-6 col-md-3 wow fadeInUp"
              data-wow-duration="2.5s"
            >
              <div className="single-social-list bitcoin">
              <i class="fa fa-linkedin" aria-hidden="true"></i>
                <h3 className="xs-title sm">Linkedin</h3>
                <a href="https://www.linkedin.com/in/islamictijarat" target='blank'>
                  <span className="icon icon-arrow-right" />
                </a>
              </div>
            </div>
            {/* single-social-list*/}
            <div
              className="col-lg col-6 col-md-3 wow fadeInUp"
              data-wow-duration="3s"
            >
              <div className="single-social-list youtube">
                <i className="fa fa-youtube-play" />
                <h3 className="xs-title sm">Youtube</h3>
                <a href="https://www.youtube.com/islamictijarat" target='blank'>
                  <span className="icon icon-arrow-right" />
                </a>
              </div>
            </div>
            {/* single-social-list*/}
            <div
              className="col-lg col-6 col-md-3 wow fadeInUp"
              data-wow-duration="3.5s"
            >
              <div className="single-social-list medium">
              <i class="fa fa-whatsapp" aria-hidden="true"></i>
                <h3 className="xs-title sm">Whatsapp</h3>
                <a href="https://whatsapp.com/channel/0029VakHKaFJENy4EzxloA13" target='blank'>
                  <span className="icon icon-arrow-right" />
                </a>
              </div>
            </div>
            {/* single-social-list*/}
          </div>{" "}
          {/* Row End */}
        </div>
      </section>{" "}
      {/* social list section end */}
    </div>
    {/* footer section start */}
   <Footer />
    {/* footer section end */}
  </div>
  
  )
}

export default Blogandfooter